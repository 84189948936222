import { getUserDetailsByEmail, logToBackendLogFile } from "../externalLayerAccessor/BackEndRequests";
import { ProgrammingLanguage } from "../pages/Settings";
import { AccessLevel, ChallengeTestCase, EMAIL_TO_USER_ID_MAPPING, LOCAL_STORAGE_LEARNING_PATH, Language, NOT_SIGNED_IN_USER_USER_ID, TestCase } from "./Constants";

export const doNothing = () => { }; // does nothing

// create an async doNothing function
export const doNothingAsync = async () => { }; // does nothing

export function convertChallengeTestCaseToCodeExecutionTestCase(testCases: ChallengeTestCase[]): TestCase[] {
  const codeExecutionTestCases: TestCase[] = [];
  testCases.forEach((testCase) => {
    codeExecutionTestCases.push({
      stdin: testCase.input,
      expected_output: testCase.expected_output,
    });
  });
  return codeExecutionTestCases;
}

export function convertLanguageToCodeExecutionLanguage(language: Language): string {
  switch (language) {
    case Language.Python:
      return "Python";
    case Language.JavaScript:
      return "JavaScript";
    case Language.Java:
      return "Java";
    case Language.Go:
      return "Go";
    case Language.C:
      return "C";
    case Language.Typescript:
      return "TypeScript";
    case Language.Swift:
      return "Swift";
    case Language.Kotlin:
      return "Kotlin";
    case Language['C++']:
      return "C++";
    case Language['C#']:
      return "C#";
    default:
      console.error(`Language ${language} not supported`);
      return "";
  }
}

export function convertToProgrammingLanguage(language: Language): ProgrammingLanguage {
  switch (language) {
    case Language.Python:
      return ProgrammingLanguage.Python;
    case Language['C++']:
      return ProgrammingLanguage.Cpp;
    case Language.JavaScript:
      return ProgrammingLanguage.JavaScript;
    case Language.Java:
      return ProgrammingLanguage.Java;
    case Language.Go:
      return ProgrammingLanguage.Go;
    case Language['C#']:
      return ProgrammingLanguage.Csharp;
    case Language.C:
      return ProgrammingLanguage.C;
    case Language.Typescript:
      return ProgrammingLanguage.Typescript;
    case Language.Swift:
      return ProgrammingLanguage.Swift;
    case Language.Kotlin:
      return ProgrammingLanguage.Kotlin;
    default:
      throw new Error(`Language ${language} not supported in ProgrammingLanguage enum.`);
  }
}

// Enables Language type to be used as a key to CodeBlockByLanguage type
export function mapLanguagetoCodeBlockLanguageLabel(language: Language) { // NITO-language-single-source
  switch (language) {
    case Language.Python:
      return 'Python';
    case Language['C++']:
      return 'C++';
    case Language.Java:
      return 'Java';
    case Language.JavaScript:
      return 'JavaScript';
    case Language['C#']:
      return 'C#';
    case Language.Go:
      return 'Go';
    case Language.C:
      return 'C';
    case Language.Typescript:
      return 'TypeScript'
    case Language.Kotlin:
      return 'Kotlin'
    case Language.Swift:
      return 'Swift'
  }
}


export function cacheUserIdByEmailMapping(email: string, userId: number): void {
  const emailToUserIdMapping = JSON.parse(localStorage.getItem(EMAIL_TO_USER_ID_MAPPING) || '{}');
  emailToUserIdMapping[email] = userId;
  localStorage.setItem(EMAIL_TO_USER_ID_MAPPING, JSON.stringify(emailToUserIdMapping));
}

// Define a helper function for getting the userId based on an email
export function getUserIdByEmailFromCache(email: string): number | null {
  const emailToUserIdMapping = JSON.parse(localStorage.getItem(EMAIL_TO_USER_ID_MAPPING) || '{}');
  return emailToUserIdMapping[email] || null;
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export async function getUserIdByEmailFromBackend(email: string, maxRetries: number = 3): Promise<number> {
  let retryCount = 0;
  let lastError;

  while (retryCount < maxRetries) {
    try {
      const userDetails = await getUserDetailsByEmail(email);
      if (userDetails && userDetails.id) {
        return userDetails.id;
      } else {
        // Handle case where user details don't have an id or are not as expected
        console.error("Received unexpected user details format", userDetails);
        retryCount++;
        if (retryCount < maxRetries) await delay(1000);  // wait for 1 second before retrying
      }
    } catch (error) {
      lastError = error;  // save the last error
      console.error("Error fetching user details by email", error);
      retryCount++;
      if (retryCount < maxRetries) await delay(1000);  // wait for 1 second before retrying
    }
  }

  console.error("Failed to fetch user details after maximum retries");
  if (lastError) {
    throw lastError;  // throw the last encountered error
  } else {
    throw new Error("Received unexpected user details format after maximum retries");
  }

}


export function learningPathExistsInDataLayer(): boolean {
  const learningPath = localStorage.getItem(LOCAL_STORAGE_LEARNING_PATH);
  return Boolean(learningPath); // this will return false if learningPath is null or undefined
}

export function isUserSignedIn(userId: number | null): boolean {
  if(!userId || userId === NOT_SIGNED_IN_USER_USER_ID) {
    return false;
  }
  return true;
}

export function openUrlInNewTab(url: string, userId?: number): void {
  window.open(url, '_blank')

  if (userId) {
    // Log the user's click on the link
    logToBackendLogFile(
      `User clicked on link: ${url}`,
      "info",
      userId);
  }
}

export function openUrl(url: string, openInNewTab: boolean = false, userId?: number): void {
  if (openInNewTab) {
    window.open(url, '_blank');
  } else {
    window.location.href = url;
  }

  if (userId) {
    // Log the user's click on the link
    logToBackendLogFile(
      `User clicked on link: ${url}`,
      "info",
      userId);
  }
}

export const getAccessLevelString = (accessLevel: AccessLevel): string => {
  switch (accessLevel) {
    case AccessLevel.Premium:
      return 'Premium';
    case AccessLevel.Standard:
      return 'Standard';
    case AccessLevel.ComingSoon:
      return 'Coming soon';
    default:
      return 'Unknown';
  }
};