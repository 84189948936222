import { useState, useEffect } from 'react';

// Hook to track window size
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Avoid accessing 'window' during server-side rendering
    const updateWindowSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Set initial window size if in the browser
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }

    window.addEventListener('resize', updateWindowSize);

    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
