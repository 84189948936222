import React, { useContext, useEffect, useState } from 'react';
import { UserSubscription, UserSubscriptionTier } from '@/externalLayerAccessor/models/firebaseModels';
import StateAccessor from '@/StateAccessor';
import { isUserSignedIn } from '@/utils/HelperFunctions';
import { useNavigate } from 'react-router-dom';
import { getSubscriptionByUserEmail, userHasPremiumSubscriptionPrivilege } from '@/utils/PermissionUtils';
import { logToBackendLogFile } from '@/externalLayerAccessor/BackEndRequests';
import DOMPurify from 'dompurify';

import '../../css/Subscriptions.css';
import { Alert, Button } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import PaymentIcon from '@mui/icons-material/Payment';
import AssistantIcon from '@mui/icons-material/Assistant';
import GroupIcon from '@mui/icons-material/Group';

import { UserResourceUsage, getResourceUsageByUserId } from '@/externalLayerAccessor/userResourcesAccessor';
import { CheckoutDetail, CheckoutMode, createSubscriptionStripeCheckoutSession, PaymentMethodType, SubscriptionMetaData } from '@/externalLayerAccessor/paymentsServiceAccessor';
import Modal from '@/utils/Modal';

/////////////////////////////////////////////
//////////// Payment Success View ///////////
/////////////////////////////////////////////

interface PaymentSuccessPageProps {
}

const PaymentSuccessPage: React.FC<PaymentSuccessPageProps> = () => {
  return (
    <div className='payment-success-container'>
      <h1>Payment Successful</h1>
      <p>Your payment was processed successfully. Thank you!</p>
      <p>You will receive an email confirmation shortly.</p>
    </div>
  );
};
/////////////////


/////////////////////////////////////////////
//////////// Payment Cancelled View ///////////
/////////////////////////////////////////////
const PaymentCancelledPage: React.FC = () => (
  <div className='payment-cancelled-container'>
    <h1>Payment Cancelled</h1>
    <p>Your payment was cancelled. Please try again or contact support if you need assistance.</p>
  </div>
);
/////////////////






/////////////////////////////////////////////
////////////Subscription View  ///////////
/////////////////////////////////////////////

enum SubscriptionDuration {
  ONE_MONTH = '1 month',
  THREE_MONTHS = '3 months',
  SIX_MONTHS = '6 months',
  ONE_YEAR = '1 year',
  TEST_ONE_MONTH = 'test'
}

const convertSubscriptionDurationToNumberOfMonths = (duration: SubscriptionDuration): number => {
  switch (duration) {
    case SubscriptionDuration.ONE_MONTH:
      return 1;
    case SubscriptionDuration.THREE_MONTHS:
      return 3;
    case SubscriptionDuration.SIX_MONTHS:
      return 6;
    case SubscriptionDuration.ONE_YEAR:
      return 12;
    case SubscriptionDuration.TEST_ONE_MONTH:
      return 1;
    default:
      throw new Error(`Invalid subscription duration: ${duration}, could not convert to number of months`);
  }
};

const priceIdTable: Readonly<Record<SubscriptionDuration, string>> = {
  [SubscriptionDuration.ONE_MONTH]: 'price_1PhZrCJcbbTCWMabx4Ssc56V',        // Replace with actual price ID
  [SubscriptionDuration.THREE_MONTHS]: 'price_1Pha2HJcbbTCWMabKhOcRqYM',  // Replace with actual price ID
  [SubscriptionDuration.SIX_MONTHS]: 'price_1PhaR0JcbbTCWMabBr5veixT',      // Replace with actual price ID
  [SubscriptionDuration.ONE_YEAR]: 'price_1PhZxFJcbbTCWMabw8BklTaf',          // Replace with actual price ID
  [SubscriptionDuration.TEST_ONE_MONTH]: 'price_1Pih4RJcbbTCWMabnfC01J2U'           // This is a test price ID
};


interface ResourceAvailabilityViewProps {
  loading: boolean;
  resourceUsage: UserResourceUsage | null;
}

const ResourceAvailabilityView: React.FC<ResourceAvailabilityViewProps> = ({ loading, resourceUsage }) => {
  const formatDate = (date: Date) => {
    return new Date(date).toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'GMT', // Specify the timezone explicitly
      timeZoneName: 'short',
    });
  };

  return (
    <div className='resource-availability-view'>
      {loading && <p>Loading resource usage...</p>}
      {resourceUsage && (
        <div>
          <h2>Available Resources</h2>
          <p>
            <strong><CodeIcon /> Code Execution:</strong> {resourceUsage.codeExecution ? `${resourceUsage.codeExecution.remainingUnits} units remaining (last used on ${formatDate(resourceUsage.codeExecution.lastUsedAt)})` : 'No data'}
          </p>
          <p>
            <strong><AssistantIcon /> AI Assistant:</strong> {resourceUsage.ai_assistant ? `${resourceUsage.ai_assistant.remainingUnits} units remaining (last used on ${formatDate(resourceUsage.ai_assistant.lastUsedAt)})` : 'No data'}
          </p>
        </div>
      )}
    </div>
  );
};

interface SubscriptionDetailsViewProps {
  userEmail: string;
  subscription: UserSubscription | null;
}

const SubscriptionDetailsView: React.FC<SubscriptionDetailsViewProps> = ({ userEmail, subscription }) => {
  return (
    <div className='subscription-details-view'>
      <h2>Basic Details</h2>
      <p><strong>Email address:</strong> {userEmail}</p>
      <p><strong>Current subscription tier:</strong> {subscription?.subscriptionTier || UserSubscriptionTier.FREE}</p>
      {subscription && (
        <p>
          <strong>Subscription expiry date: </strong>
          {subscription.subscriptionExpiry ?
            new Date(subscription.subscriptionExpiry).toLocaleString(undefined, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              timeZone: 'UTC',
              timeZoneName: 'short',
            })
            : "None"}
        </p>
      )}
    </div>
  );
};

const getUTCDateNow = (): Date => {
  return new Date(Date.UTC(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    new Date().getUTCHours(),
    new Date().getUTCMinutes(),
    new Date().getUTCSeconds(),
    new Date().getUTCMilliseconds()
  ));
};

const calculateNewExpiryUTCDate = (currentExpiryDate: Date | null, duration: SubscriptionDuration): Date => {
  const nowInUTC: Date = getUTCDateNow();

  // if expiry date is in the future, then any extension should be atop the current expiry date, otherwise, start from now
  const startUTCDateToExtendFrom: Date = (currentExpiryDate && currentExpiryDate > nowInUTC)
    ? currentExpiryDate
    : nowInUTC;

  let newExpiryDate: Date;

  switch (duration) {
    case SubscriptionDuration.TEST_ONE_MONTH:
    case SubscriptionDuration.ONE_MONTH:
      newExpiryDate = new Date(startUTCDateToExtendFrom.setMonth(startUTCDateToExtendFrom.getMonth() + 1));
      break
    case SubscriptionDuration.THREE_MONTHS:
      newExpiryDate = new Date(startUTCDateToExtendFrom.setMonth(startUTCDateToExtendFrom.getMonth() + 3));
      break;
    case SubscriptionDuration.SIX_MONTHS:
      newExpiryDate = new Date(startUTCDateToExtendFrom.setMonth(startUTCDateToExtendFrom.getMonth() + 6));
      break;
    case SubscriptionDuration.ONE_YEAR:
      newExpiryDate = new Date(startUTCDateToExtendFrom.setFullYear(startUTCDateToExtendFrom.getFullYear() + 1));
      break;
    default:
      logToBackendLogFile('Invalid subscription duration selected', "error");
      throw new Error('Invalid subscription duration selected');
  }

  return newExpiryDate;
}

enum PaymentStatus {
  SUCCESS = 'success',
  CANCELLED = 'cancelled'
}


const Subscriptions: React.FC = () => {
  const { userId, userName, setUserName, userEmail } = useContext(StateAccessor);
  const [subscription, setSubscription] = useState<UserSubscription | null>(null);
  const [subscriptionDuration, setSubscriptionDuration] = useState<SubscriptionDuration>(SubscriptionDuration.ONE_MONTH);
  const [resourceUsage, setResourceUsage] = useState<UserResourceUsage | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [clientFacingErrMsg, setClientFacingErrMsg] = useState<string | null>(null);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState<boolean>(false);
  const [showPaymentCancelledModal, setShowPaymentCancelledModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const onLoadSubscriptionSynchronizationDelayInSecs: number = 2000;

  useEffect(() => {
    if (isUserSignedIn(userId)) {
      try {
        syncSubscriptionDetails();
        SyncAvailableUserResourcesDetails();
      } catch (e) {
        setClientFacingErrMsg("An internal error occurred while fetching your subscription details please retry or contact support");
      }
      finally {
        setLoading(false);
      }
    }
  }, [userId, navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paymentStatus = urlParams.get('paymentStatus');

    if (paymentStatus === PaymentStatus.SUCCESS) {
      setShowPaymentSuccessModal(true);
    } else if (paymentStatus === PaymentStatus.CANCELLED) {
      setShowPaymentCancelledModal(true);
    }

    // edit the URL to remove the payment status query param
    urlParams.delete('paymentStatus');

    // Update the URL without reloading the page
    window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
  }, [location.search]); // `location.search` means just the query string part of the URL

  const syncSubscriptionDetails = async () => {
    try {
      const userSubscription = await getSubscriptionByUserEmail(userEmail);
      setSubscription(userSubscription);
    } catch (e) {
      console.error("Error fetching subscription details:", e);
    }
  }

  const getPriceIdBySubscriptionDuration = (duration: SubscriptionDuration): string => {
    if (!(duration in priceIdTable)) {
      throw new Error(`Invalid subscription duration: ${duration}`);
    }
    return priceIdTable[duration];
  }

  const processSubscription = async (currentExpiryDate: Date | null) => {
    try {
      const newExpiryUTCDate = calculateNewExpiryUTCDate(currentExpiryDate, subscriptionDuration);

      const subscriptionDetails: UserSubscription = {
        displayName: userName,
        userEmail: userEmail,
        subscriptionTier: UserSubscriptionTier.PREMIUM,
        subscriptionExpiry: newExpiryUTCDate,
      };

      const checkoutDetail: CheckoutDetail = {
        payment_method_types: [PaymentMethodType.CARD],
        line_items: [
          {
            price_id: getPriceIdBySubscriptionDuration(subscriptionDuration),
            quantity: 1
          }
        ],
        mode: CheckoutMode.PAYMENT,
      };


      const subscriptionMetaData: SubscriptionMetaData = {
        durationInMonths: convertSubscriptionDurationToNumberOfMonths(subscriptionDuration)
      };
      
      await createSubscriptionStripeCheckoutSession(userId, checkoutDetail, subscriptionDetails, subscriptionMetaData);
    } catch (e) {
      logToBackendLogFile(`An error occured while processing subscription for user: ${userEmail}. Error details: ${e}`, "error");
      setClientFacingErrMsg("An internal error occurred while processing your subscription please retry or contact support");
    }
  };


  const handleCreatePremiumSubscription = async () => {
    logToBackendLogFile(
      `User tiggered premium subscription creation`,
      "info",
      userId);
    await processSubscription(null);
  };

  const handleExtendPremiumSubscription = async () => {
    const currentExpiryDate = subscription?.subscriptionExpiry || null;
    await processSubscription(currentExpiryDate);
  };

  const userHasPremiumAccess = (): boolean => {
    return userHasPremiumSubscriptionPrivilege(subscription?.subscriptionTier || UserSubscriptionTier.FREE);
  }

  const SyncAvailableUserResourcesDetails = async () => {
    try {
      const usage = await getResourceUsageByUserId(userId);
      setResourceUsage(usage);
      setClientFacingErrMsg(null);
    } catch (e) {
      //console.error("Error fetching resource usage:", e);
      setClientFacingErrMsg("An internal error occurred while fetching your details please retry or contact support");
    }
  }

  const handleUserNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedInput = DOMPurify.sanitize(e.target.value);
    setUserName(sanitizedInput);
  }


  return (
    <div className='root-container'>

      {clientFacingErrMsg && (
        <div className="notification-container">
          <Alert severity="warning">
            {clientFacingErrMsg}
          </Alert>
        </div>
      )}


      <div className='subscription-status-modal-view-container'>
        {showPaymentSuccessModal && (
          <Modal
            content={
              <PaymentSuccessPage/>
            }
            onClose={() => {
              setShowPaymentSuccessModal(false);
            }}
          />
        )}

        {showPaymentCancelledModal && (
          <Modal
            content={
              <PaymentCancelledPage />
            }
            onClose={() => {
              setShowPaymentCancelledModal(false);
            }}
          />
        )}
      </div>




      {isUserSignedIn(userId) && (
        <>
          <div className='welcome-container'>
            <p>Welcome {userName}</p>
          </div>
          <div className='subscription-container' >
            <SubscriptionDetailsView userEmail={userEmail} subscription={subscription} />
            <ResourceAvailabilityView loading={loading} resourceUsage={resourceUsage} />
            <PremiumSubscriptionView 
                userName={userName} 
                userHasPremiumAccess={userHasPremiumAccess}
                handleUserNameInput={handleUserNameInput} 
                subscriptionDuration={subscriptionDuration} 
                setSubscriptionDuration={setSubscriptionDuration} 
                handleCreatePremiumSubscription={handleCreatePremiumSubscription} 
                handleExtendPremiumSubscription={handleExtendPremiumSubscription} 
              />
          </div>
        </>
      )}

      {!isUserSignedIn(userId) && (
        <div>
          <Alert severity="warning">
            You need to be signed in to access this page. Please sign-up or sign-in to continue.
          </Alert>
        </div>
      )}

    </div>
  );
};


interface PremiumSubscriptionViewProps {
  userName: string;
  userHasPremiumAccess: () => boolean;
  handleUserNameInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  subscriptionDuration: SubscriptionDuration;
  setSubscriptionDuration: (duration: SubscriptionDuration) => void;
  handleCreatePremiumSubscription: () => void;
  handleExtendPremiumSubscription: () => void;
}

const PremiumSubscriptionView: React.FC<PremiumSubscriptionViewProps> = ({
  userName,
  handleUserNameInput,
  subscriptionDuration,
  setSubscriptionDuration,
  handleCreatePremiumSubscription,
  handleExtendPremiumSubscription,
  userHasPremiumAccess
}) => {
  const [showSubscriptionOptionsModal, setShowSubscriptionOptionsModal] = useState<boolean>(false);

  return (
    <div className="premium-subscription-view">
        <div>

        {!userHasPremiumAccess() ? (
          <>
            <div className='title'><h2>Unlock Full Access with Coditioning Premium</h2></div>
            <div className='subtitle'>
              <h3><GroupIcon style={{ marginRight: '0.5rem' }} />Join 1000+ members</h3>
            </div>
          </>
        ) : null}

          <div className='subscription-options-container'>
            <div className='subscription-option-features'>
              <h2>Coditioning Basic</h2>
                <div className='features-list'>
                  <p className="feature">✅ Basic diagnostic tests</p>
                  <p className="feature">✅ Custom learning path</p>
                  <p className="feature">✅ Non-premium content</p>
                  {/* <p className="feature">✅ feature</p>
                  <p className="feature">✅ feature</p>
                  <p className="feature">✅ feature</p>
                  <p className="feature">✅ feature</p> */}
                </div>
            </div>

            <div className='subscription-option-features'>
              <h2>👑 Coditioning Premium</h2>
              <div className='features-list'>
                <p className="feature">✅ Everything in basic plan</p>
                <p className="feature">✅ AI Mock Interviews (Beta access)</p>
                <p className="feature">✅ AI Learning Assistant</p>
                <p className="feature">✅ System Design Course Full Access</p>
                <p className="feature">✅ Low Level Design Course Full Access</p>
                <p className="feature"> ✅ Algorithms & Data Structures Full Access</p>
                {/* <p className="feature">✅ AI Mock Interviews (Beta access)</p>
                  <p className="feature">✅ AI Assistant </p>
                  <p className="feature">✅ feature</p>
                  <p className="feature">✅ feature</p>
                  <p className="feature">✅ feature</p>
                  <p className="feature">✅ feature</p>
                  <p className="feature">✅ feature</p> */}

                <Button
                  className='payment-button'
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowSubscriptionOptionsModal(true)}
                  style={{
                    marginTop: '0.5rem',
                    backgroundImage: 'linear-gradient(189deg, #ffff99, #fdd835)',
                    color: 'black',
                    textTransform: 'none'
                  }}
                >
                  <PaymentIcon style={{ marginRight: '0.5rem' }} />
                  {!userHasPremiumAccess() ? "Subscribe to Premium": "Extend Premium Subscription"}

                </Button>
              </div>

              
            </div>
          </div>
        </div>


    {showSubscriptionOptionsModal && (
      <Modal 
        content={ 
                <div className="subscription-form-view">
                        <div>
                          <label>
                            Display Name:
                            <input type="text" value={userName} onChange={handleUserNameInput} title='Enter your preferred display name here.' />
                          </label>
                        </div>
                        <div>
                          <label>
                            Subscription Duration:
                            <select value={subscriptionDuration} onChange={(e) => setSubscriptionDuration(e.target.value as SubscriptionDuration)}>
                              <option value={SubscriptionDuration.ONE_MONTH}>1 Month (non-recurring)</option>
                              <option value={SubscriptionDuration.THREE_MONTHS}>3 Months (non-recurring)</option>
                              <option value={SubscriptionDuration.SIX_MONTHS}>6 Months (non-recurring)</option>
                              <option value={SubscriptionDuration.ONE_YEAR}>1 Year (non-recurring)</option>
                              {process.env.REACT_APP_NODE_ENV === 'development' && <option value={SubscriptionDuration.TEST_ONE_MONTH}>Test</option>}
                            </select>
                          </label>
                        </div>
                        <button
                          onClick={
                            () => { userHasPremiumAccess() ? handleExtendPremiumSubscription() : handleCreatePremiumSubscription(); }
                          }
                          disabled={false}
                        >
                          {userHasPremiumAccess() ? 'Extend Premium Subscription' : 'Subscribe to Premium'}
                        </button>
                </div>
        }
        onClose={() => {
          setShowSubscriptionOptionsModal(false);
        }}
      />
    )}
    </div>
  );
};












export default Subscriptions;
