import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { openUrlInNewTab } from './HelperFunctions';
import StateAccessor from '../StateAccessor';


import '../css/BannerButton.css';
import { CODITIONING_WORKSHOP_OR_HANGOUT_BANNER_MSG, HUMAN_INTERVIEWER_COACH_PATH, WORKSHOPS_PAGE_PATH } from './Constants';

export enum BannerPosition {
    Top = 'top',
    Bottom = 'bottom'
}

export interface BannerButtonProps {
    url: string;
    text: string;
    backgroundImage?: string;
    textColor?: string;
    position?: BannerPosition;
    appearAfterTimeInSec?: number | null; // this controls if banner should appear immediately or after a certain time
}

/*
This component is used to display banner messages that can be clicked to open a url in a new tab.
*/
const BannerButton: React.FC<BannerButtonProps> = (
    {
        url,
        text,
        backgroundImage = 'linear-gradient(189deg, #ffff99, #fdd835)',
        textColor = 'black',
        position = BannerPosition.Top,
        appearAfterTimeInSec = null
    }) => {
    const { userId } = useContext(StateAccessor);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {

        let timer: NodeJS.Timeout | null = null;

        if (appearAfterTimeInSec !== null) {
            timer = setTimeout(
                () => { setIsVisible(true); },
                appearAfterTimeInSec * 1000
            );
        } else {
            setIsVisible(true);
        }


        return () => {
            if (timer) {
                clearTimeout(timer);// clear the timer if the component is unmounted so that it doesn't run after the component is unmounted
            }
        };
    }, [appearAfterTimeInSec]);


    if (!isVisible) {
        return null;
    }

    return (
        <Button
            className='join-workshop-button'
            variant="contained"
            color="primary"
            endIcon={<EventNoteIcon />}
            onClick={() => openUrlInNewTab(url, userId)}
            sx={{
                color: textColor,
                fontSize: '14px',
                textTransform: 'none',
                backgroundImage: backgroundImage,
                position: position === BannerPosition.Bottom ? ['-webkit-sticky', 'sticky'] : 'relative',
                bottom: position === BannerPosition.Bottom ? 0 : 'auto',
                paddingRight: '1px',
                zIndex: 99,
            }}
        >
            <IconButton
                aria-label="close"
                onClick={(event) => {
                    event.stopPropagation(); // Prevent the button's onClick from being called
                    setIsVisible(false);
                }}
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                }}
            >
                <CloseIcon />
            </IconButton>
            {text}

        </Button>
    );
};


interface CustomBannerButtonProps {
    backgroundImage?: string;
    textColor?: string;
    position?: BannerPosition;
    appearAfterTimeInSec?: number | null;
}

export const FreeWorkshopBannerButton: React.FC<CustomBannerButtonProps> = (
    {
        backgroundImage = 'linear-gradient(189deg, #ffff99, #fdd835)',
        textColor = 'black',
        position = BannerPosition.Bottom,
        appearAfterTimeInSec = null
    }) => {
    return (
        <BannerButton
            url={WORKSHOPS_PAGE_PATH}
            text={CODITIONING_WORKSHOP_OR_HANGOUT_BANNER_MSG}
            backgroundImage={backgroundImage}
            textColor={textColor}
            position={position}
            appearAfterTimeInSec={appearAfterTimeInSec}
        />
    );
}


export const DiscountBannerButton: React.FC<CustomBannerButtonProps> = ({
    backgroundImage = 'linear-gradient(189deg, #ffff99, #fdd835)',
    textColor = 'black',
    position = BannerPosition.Top,
    appearAfterTimeInSec = null
}) => {
    return (
        <BannerButton
            url={HUMAN_INTERVIEWER_COACH_PATH}
            text={"10% off Mock Interviews. Use code: GETTRULYREADY10"} 
            backgroundImage={backgroundImage}
            textColor={textColor}
            position={position}
            appearAfterTimeInSec={appearAfterTimeInSec}
        />
    );
}


export default BannerButton;