import React, { useState } from 'react';
import '../css/Careers.css';
import { Link } from 'react-router-dom'; // Import Link if not already imported
import { CAREERS_CONTACT_EMAIL } from '@/utils/Constants';

const GROWTH_ENGINEER_INTERN_QUESTIONAIRE_GOOGLE_FORM_URL = 'https://forms.gle/aQy7rTYDZT6sTwVi7';

const Careers: React.FC = () => {
  const [title] = useState('Software Engineer Intern (Growth) (Remote)');
  const [isHiring] = useState(true); // Add isHiring state. Set to true when hiring
  const description = `
    <p>Coditioning is looking for a Growth Software Engineer Intern to join our team. This is a hybrid role where you'll engage in both growth optimization tasks and front-end development projects.</p>
    
    <h3>Candidate Requirements:</h3>
    <ul>
      <li>Takes pride in the quality of their work</li>
      <li><strong>Strong</strong> command of the English language, and can communicate effectively verbally and in writing</li>
      <li>Keen and quick to learn new tools and technologies, especially AI tools to boost productivity.</li>
      <li>Interest (no prior experience needed) in growth marketing and optimization (SEO, analytics, A/B testing).</li>
      <li>Good problem-solving skills and a strong attention to detail.</li>
      <li>Ability to commit at least 10 hours a week.</li>
      <li>Experience with React, TypeScript, HTML and CSS (Python is a plus).</li>
    </ul>

    <h3>Growth Responsibilities include:</h3>
    <ul>
      <li>Set up and manage tools like Google Search Console and Analytics to track performance and optimize traffic and conversions.</li>
      <li>Run and evaluate paid ad campaigns to drive immediate traffic and gain insights on keyword performance.</li>
      <li>Optimize the website for SEO based on research and campaign data.</li>
      <li>Collaborate on A/B testing and conversion rate optimization initiatives.</li>
      <li>Engage with online communities to drive organic traffic and improve search rankings.</li>
    </ul>
    
    <h3>Software Development Responsibilities include:</h3>
    <ul>
      <li>Work on front-end development tasks, implementing new features and improving the user experience.</li>
      <li>Optimize the front-end codebase with performance in mind, including potential A/B tests for feature optimizations.</li>
      <li>Be open to back-end development (optional)</li>
    </ul>
    
    <br />
    <h3>How to apply:</h3>
    <ul>
      <li>
        <a class="questionnaire" href="${GROWTH_ENGINEER_INTERN_QUESTIONAIRE_GOOGLE_FORM_URL}" target="_blank" rel="noopener noreferrer">
          Tell us about your interests by filling this 5-minute questionnaire
        </a>
      </li>
      <li>Send us your CV, and feel free to share any portfolio or relevant projects you've worked on.</li>
    </ul>
  `;

  const [email] = useState(CAREERS_CONTACT_EMAIL);
  const [hasConsented, setHasConsented] = useState(false); // Consent state

const handleApplyClick = () => {
  if (hasConsented) {
    const emailTitle = !isHiring ? `Submitting Application for future internship opportunities` : `Applying for ${title}`;
    window.open(`mailto:${email}?subject=${encodeURIComponent(emailTitle)}`);
  } else {
    alert('Please consent to the privacy policy before applying');
  }
};

  return (
    <div className="careers">
      {!isHiring && <div className="careers__hiringStatus">Hiring is currently paused but you can apply for future openings</div>}
      <h2 className="careers__title">{title}</h2>
      <div className="careers__description" dangerouslySetInnerHTML={{ __html: description }} />

      {isHiring ?
        <button className="careers__applyButton" onClick={handleApplyClick}>
          Apply via Email
        </button>
        :
        <React.Fragment>
          <p className="careers__status">Hiring is currently paused. You can still submit your application for future consideration.</p>
          <button className="careers__applyButton" onClick={handleApplyClick}>
            Submit for future consideration
          </button>
        </React.Fragment>
      }

      <div className="careers__consent">
        <input type="checkbox" id="consent" name="consent" onChange={() => setHasConsented(!hasConsented)} />
        <label htmlFor="consent"> I have read and agree to the <Link to="/privacy" target="_blank" rel="noopener noreferrer">privacy policy</Link>.</label>
      </div>

      <i> all application emails should be sent to {email}</i>
    </div>
  );
};

export default Careers;
